import styled from "styled-components"

const Title = styled.h1`
    font-size: 2.5rem;
    color: #00BFA5;
    font-weight: 700;
    margin-bottom: 1rem;
`

const Subtitle = styled.h1`
    font-size: 1.2rem;
    color: #37474F;
    font-weight: 400;
    margin-bottom: 2rem;

    strong{
        font-size: 1.2rem;
        font-weight: 600;
    }
`

const Line = styled.div`
    width: 15rem;
    height: 1rem;
    border-top: 1px solid #00BFA5;
`

export default function Header(){

    return (
        <>
            <Title>Bem-vindo(a) ao PHILA!</Title>
            <Line/>
            <Subtitle>Selecione o <strong>módulo que deseja acessar</strong> para prosseguir</Subtitle>
        </>
    )
}