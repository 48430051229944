import styled from "styled-components";

export const Page = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: #00BFA5;
`

export const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: auto;
    padding: 6rem;
    align-items: center;
    justify-content: center;
    width: 60vw;
    height: 100vh;
    background-color: white;
    text-align: center;
    overflow: auto;
`