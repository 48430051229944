import Button from './components/Button';
import LeftSection from './components/LeftSection';
import { Page, RightContainer } from './styles';
import auth_icon from './img/auth-icon.png'
import panel_icon from './img/panel-icon.png'
import ies_icon from './img/ies-icon.png'
import Header from './components/Header';
import MoreButton from './components/MoreButton';
import Footer from './components/Footer';
import { useEffect, useState } from 'react';
import MobileWarningPage from './components/MobileWarningPage';

function App() {

  const [isMobile, setIsMobile] = useState(null)

  useEffect(() => {

    const checkIsMobile = () => {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) setIsMobile(true)
    }

    checkIsMobile()
  },[])
  
  if(isMobile) return <MobileWarningPage/>
  
  return (
    <Page>
      <LeftSection/>
      <RightContainer>
        <Header/>
        <Button
          img={auth_icon}
          title={'Login no Phila'}
          description={<h2>Faça o login no <strong>Sistema de Gestão de Atendimento</strong> ou no <strong>Painel de Controle de Atendimento</strong> do Sistema Phila</h2>}
          onClick={() => window.open("https://login.phila.com.br","_self")}
        />
        <Button
          img={panel_icon}
          title={'Painel de Senhas'}
          description={<h2>Acesse o Painel de que <strong>exibirá as senhas chamadas</strong> pelos atendentes</h2>}
          onClick={() => window.open("https://panel.phila.com.br","_self")}
        />
        <Button
          img={ies_icon}
          title={'Emissão de Senhas'}
          description={<h2>Acesse a <strong>Interface de Emissão de Senhas</strong> que fará a <strong>impressão das senhas</strong> para seus clientes</h2>}
          onClick={() => window.open("https://ies.phila.com.br","_self")}
        />
        <MoreButton/>
        <Footer/>
      </RightContainer>
    </Page>
  );
}

export default App;
