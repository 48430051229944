import styled from "styled-components"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    margin-top: auto;
    position: absolute;
    bottom: 1rem;
`

export default function Footer(){

    return (
        <Container>
            Powered by RHOMI
            <a href="https://www.rhomi.com.br">
                www.rhomi.com.br
            </a>
        </Container>
    )
}