import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 40%;
    overflow: hidden;
    position: relative;
    padding: 5rem;

    h2{
        font-size: 2rem;
        color: white;
        font-weight: 600;
    }
`

export const Background = styled.img`
    width: 110%;
    object-fit: contain;
    position: absolute;
    bottom: -5%;
    left: -5%;
`

export const Title = styled.div`
    display: flex;
    flex-direction: column;

    img{
        width: 40%;
        object-fit: contain;
    }

    h1{
        font-size: 1.3rem;
        color: white;
        font-weight: 300;
        margin-bottom: 2rem;

        strong{
            font-size: 1.3rem;
            color: #025D7A;
            font-weight: 800;
        }
    }
`