import styled from "styled-components"
import desktop from '../../img/desktop.png'
import logo from '../../img/logo_main_dark.png'
import MoreButton from "../MoreButton"

const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100vh;
    width: 100vw;
`

const Desktop = styled.img`
    width: 60%;
`

const Logo = styled.img`
    width: 60%;
`

const Text = styled.p`
    margin: 2rem;
    text-align: center;
    font-size: 2rem;
    color: #025D7A;
    font-weight: 400;

    strong{
        font-size: 2rem;
        font-weight: 600;
    }
`

const Title = styled.p`
    text-decoration: underline;
    text-align: center;
    font-size: 2rem;
    color: #025D7A;
    font-weight: 900;
`

export default function MobileWarningPage(){


    return (
        <Page>
            <Logo src={logo}/>
            <Desktop src={desktop}/>
            <div>
                <Title>ATENÇÃO!</Title>
                <Text>
                    Para acessar o <strong>Sistema PHILA</strong> utilize o navegador de um <strong>computador desktop ou notebook</strong>
                </Text>
            </div>
            <MoreButton/>
        </Page>
    )
}