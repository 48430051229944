import styled from "styled-components"
import { BsArrowRightShort } from 'react-icons/bs'

const Container = styled.div`
    display: flex;
    align-items: center;
    margin-top: 2rem;
    cursor: pointer;

    h1{
        font-size: 1.2rem;
        text-decoration: underline;
        color: #025D7A;
        font-weight: 400;
    }

    &:hover{
        h1{
            font-weight: 600;
        }
    }
`

const Arrow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    
    svg{
        color: #025D7A;
        font-size: 2rem;
    }
`

export default function MoreButton(){

    return (
        <Container
            onClick={() => window.open("https://www.rhomi.com.br/","_self")}
        >
            <h1>Saiba mais</h1> 
            <Arrow>
                <BsArrowRightShort/>
            </Arrow>
        </Container>
    )
}