import styled from "styled-components"
import { HiArrowRight } from 'react-icons/hi2'

const Container = styled.div`
    display: flex;
    align-items: center;
    width: 80%;
    border: 1px solid #B0BEC5;
    border-radius: 3rem;
    margin: 1.2rem;
    padding: 2rem;
    cursor: pointer;
    transition: all 0.5s;

    &:hover{
        border-color: #00BFA5;
        background-color: #F5F5F5;
    }
`

const Image = styled.img`
    height: 5rem;
    aspect-ratio: 1/1;
`

const Description = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    margin-left: 1.5rem;
    height: auto;

    h1{
        font-size: 1.4rem;
        font-weight: 600;
        color: #00BFA5;
        margin-bottom: 0.2rem;
    }

    h2{
        font-size: 1.1rem;
        font-weight: 400;
        color: #37474F;

        strong{
            font-size: 1.1rem;
            font-weight: 600;
        }
    }
`

const Arrow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    
    svg{
        color: #025D7A;
        font-size: 3rem;
    }
`

export default function Button({img, title, description, onClick}){


    return (
        <Container onClick={onClick}>
            <Image src={img}/>
            <Description>
                <h1>{title}</h1>
                {description}
            </Description>
            <Arrow>
                <HiArrowRight/>
            </Arrow>
        </Container>
    )
}